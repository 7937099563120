import React from "react";
import { Panel } from "../Interfaces";
import DOMPurify from "dompurify";
import { marked } from "marked";
import QueryApi from "../Functions/QueryApi";

const defaultOptions = {
	ALLOWED_TAGS: [
		"b",
		"i",
		"em",
		"strong",
		"h1",
		"h2",
		"h3",
		"h4",
		"h5",
		"h6",
		"p",
		"br",
		"hr",
		"ul",
		"ol",
		"li",
		"a",
		"img",
		"blockquote",
		"pre",
		"code",
		"table",
		"thead",
		"tbody",
		"tr",
		"th",
		"td"
	]
};

export default class PanelPage extends React.Component<unknown, { panel: Panel | null }> {
	constructor(props: unknown) {
		super(props);
		this.state = {
			panel: null
		};
	}

	async componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		const questID = urlParams.get("quest");
		const panelID = urlParams.get("panel");

		if (questID === null || panelID === null) {
			window.location.href = "/";
			throw new Error("Missing details, redirecting");
		}

		const panel = await this.fetchPanel(questID, panelID);
		this.setState({ panel });
	}

	render() {
		const panel = this.state.panel;
		if (!panel) {
			return <h1>Loading...</h1>;
		}

		return (
			<>
				<h2>{panel.quest.name}</h2>
				<hr />
				<p>Created: {new Date(panel.creationDateTime).toLocaleString()}</p>
				{panel.editDateTime && <p>Last edited: {panel.editDateTime}</p>}
				<h1>{panel.name}</h1>
				<img src={panel.imageLink} alt={panel.name} width={"100%"} />
				<div
					id="content"
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(
							marked.parseInline(panel.content).split("\n").join("<br/>"),
							defaultOptions
						)
					}}
				/>
				{panel.page < panel.quest.panelCount && (
					<h3>
						<a
							title={`(Panel ${panel.page} of ${panel.quest.panelCount})`}
							href={`page?quest=${panel.quest.id}&panel=${panel.page + 1}`}
						>
							{panel.nextPanelTitle + " →"}
						</a>
						<hr />
					</h3>
				)}

				{panel.page > 1 && (
					<small>
						<a href={`page?quest=${panel.quest.id}&panel=${panel.page - 1}`}>← Previous </a>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<a href={`page?quest=${panel.quest.id}&panel=${1}`}>First</a>
					</small>
				)}
			</>
		);
	}

	async fetchPanel(quest: string, panel: string): Promise<Panel> {
		const response = await QueryApi("Shipquest/GetPanel", {
			questId: quest.toString(),
			page: panel.toString()
		});

		if (response.status !== 200) {
			throw new Error("Failed to fetch panel");
		}
		const data = await response.json();
		return data as Panel;
	}
}
