import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Quests from "./Pages/Quests";
import PanelPage from "./Pages/Panel";
import NavBar from "./Pages/Base";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Quests />
	},
	{
		path: "/page",
		element: <PanelPage />
	}
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<NavBar />
		<main>
			<RouterProvider router={router} />
		</main>
	</React.StrictMode>
);
