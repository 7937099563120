import React from "react";
import PanelLink from "../Components/PanelLink";
import { Quest } from "../Interfaces/QuestInterface";
import QueryApi from "../Functions/QueryApi";

export default class Quests extends React.Component<Record<string, never>, { quests: Quest[] }> {
	constructor(props: Record<string, never>) {
		super(props);
		this.state = {
			quests: []
		};
	}

	async componentDidMount() {
		const quests = await this.fetchQuests();
		this.setState({ quests });
	}

	render() {
		return (
			<div>
				<h1>Quests</h1>
				<ul>
					{this.state.quests.map(quest => (
						<li key={quest.name} title={quest.description}>
							<PanelLink questID={quest.id} pageID={1}>
								{quest.name} — {quest.author}
							</PanelLink>
						</li>
					))}
				</ul>
				{this.state.quests.length === 0 && <p>No quests found.</p>}
				{this.state.quests.length === 20 && (
					<a href={`/?page=${Number(new URLSearchParams(window.location.search).get("page") ?? "0") + 1}`}>
						Next page
					</a>
				)}
			</div>
		);
	}

	async fetchQuests(): Promise<Quest[]> {
		const urlParams = new URLSearchParams(window.location.search);
		const response = await QueryApi("Shipquest/GetQuests", {
			page: urlParams.get("page") ?? "0"
		});
		const data = await response.json();
		return data as Quest[];
	}
}
