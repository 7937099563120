export default async function QueryApi(
	endpoint: string,
	params: Record<string, string> = {},
	method: "GET" | "POST" | "PUT" | "DELETE" = "GET"
) {
	const url =
		method === "GET"
			? `https://quest.shiptest.net/api/${endpoint}?${new URLSearchParams(params ?? {})}`
			: `https://quest.shiptest.net/api/${endpoint}`;

	return fetch(url, {
		method: method,
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
			"User-Agent": "ShipquestClient/0.1.0"
		},
		body: method !== "GET" ? JSON.stringify(params) : null
	});
}
